<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-cloud-download font-scale="3" animation="cylon" />
        <p id="cancel-label">กรุณารอสักครู่...</p>
      </div>
    </template>
    <validation-observer ref="formRules">
      <!-- <div class="d-flex align-items-end row" style="max-width: 700px">
        <div class="row col-12">
          <b-form-group class="col-md-6 col-12 w-100" label="วันที่เริ่มต้น">
            <b-form-datepicker
              v-model="dateStart"
              placeholder="เลือกวันที่เริ่มต้น"
              class="date-input"
            />
          </b-form-group>
          <b-form-group class="col-md-6 col-12 w-100" label="วันที่สิ้นสุด">
            <b-form-datepicker
              v-model="dateEnd"
              placeholder="เลือกวันที่สิ้นสุด"
              class="date-input"
            />
          </b-form-group>
        </div>
        <div class="row col-12">
          
          <b-form-group
            class="d-flex justify-content-end align-items-end col-md-2 col-12"
          >
            <b-button
              class="rounded-sm"
              style="width: 100px"
              variant="info"
              @click="getLine()"
            >
              ค้นหา
              <i class="fas fa-search" />
            </b-button>
          </b-form-group>
          <b-form-group
            class="d-flex justify-content-end align-items-end col-md-2 col-12"
          >
            <b-button
              class="rounded-sm"
              style="width: 100px"
              variant="gradient-success"
              block
              @click="exportData()"
            >
              Export
            </b-button>
          </b-form-group>
        </div>
      </div> -->
      <div class="d-flex justify-content-start flex-wrap" style="gap: 10px">
        <!-- Date -->
        <div class="d-flex justify-content-end align-items-center">
          <b-form-group class="" label="วันที่เริ่มต้น">
            <b-form-datepicker
              v-model="dateStart"
              placeholder="เลือกวันที่เริ่มต้น"
              class="date-input mr-2 mb-1"
              :locale="locale"
              v-bind="labels[locale] || {}"
            />
          </b-form-group>
          <b-form-group class="" label="วันที่สิ้นสุด">
            <b-form-datepicker
              v-model="dateEnd"
              placeholder="เลือกวันที่สิ้นสุด"
              class="date-input mr-2 mb-1"
              :locale="locale"
              v-bind="labels[locale] || {}"
            />
          </b-form-group>
        </div>
        <!-- Date -->
        <div class="d-flex justify-content-end align-items-center">
          <b-form-group class="" label="ประเภท">
            <b-form-select
              v-model="reqType"
              class="form-control-select mb-1"
              :options="channelOptions"
            />
          </b-form-group>
          <b-form-group
            class="mx-1 mt-1 d-flex justify-content-end align-items-center"
          >
            <b-button
              style="width: 100px"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="rounded-sm"
              variant="gradient-primary"
              block
              @click="getLine()"
            >
              ค้นหา
              <i class="ms-2 fal fa-search" />
            </b-button>
          </b-form-group>
          <!-- Export -->
          <b-form-group
            class="mr-1 mt-1 d-flex justify-content-end align-items-center"
          >
            <b-button
              class="rounded-sm"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              block
              variant="gradient-success"
              @click="exportData()"
            >
              Export
            </b-button>
          </b-form-group>
          <b-form-group
            class="d-flex justify-content-end align-items-center mt-1 pr-1"
            v-if="showinput"
          >
            <b-button
              v-if="selectedCheckboxes.length == 0"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="rounded-sm"
              variant="gradient-secondary"
              block
              @click="showInputModel = true"
              >นำเข้า</b-button
            >
            <b-button
              v-if="selectedCheckboxes.length > 0"
              class="btn btn-success rounded-left"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="gradient-success"
              @click="showconfirmimportModel = true"
              >ยืนยันนำเข้า</b-button
            >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="gradient-danger"
              v-if="selectedCheckboxes.length > 0"
              class="btn btn-danger rounded-right"
              @click="resetforminput()"
              >ยกเลิกนำเข้า</b-button
            >
          </b-form-group>
        </div>
      </div>
      <b-card no-body>
        <!-- <b-card-body>
            </b-card-body> -->
        <b-table
          striped
          hover
          responsive
          show-empty
          class="position-relative items-center table-center"
          :per-page="perPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
        >
          <template #cell(index)="data" class="d-flex flex-column">
            <div v-if="checkBoxShow">
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                v-model="selectedCheckboxes"
                :value="data.item._id"
              />
            </div>

            <div>
              {{ perPage * (currentPage - 1) + (data.index + 1) }}
            </div>
          </template>
          <template #empty="scope">
            <div class="p-2 text-center">
              <img src="/box.png" alt="empty" height="40px" />
              {{
                scope.emptyFiltered
                  ? "No matching records found"
                  : "ไม่พบข้อมูล"
              }}
            </div>
          </template>

          <template #cell(detailsarea)="data">
            <div>
              {{ data.item.subdistrict_name }}
              {{ data.item.district_name }}
              {{ data.item.province_name }}
            </div>
          </template>

          <template #cell(full_name)="data">
            <div>
              {{ data.item.first_name }}
              {{ data.item.last_name }}
            </div>
          </template>

          <template #cell(detail)="data">
            <div v-if="data.item.detail">
              {{ data.item.detail }}
            </div>
            <p v-else>-</p>
          </template>
          <template #cell(editor)="data">
            <button
              type="button"
              class="btn pen rounded"
              @click="getDataReport(data)"
            >
              <i class="ms-2 icon-color-waring fas fa-pencil" />
            </button>
          </template>

          <template #cell(channel_type)="data">
            <div>
              <div>{{ data.item.channel_name }}</div>
              <div style="">
                <div v-if="data.item.line_id !== null">
                ไอดี : {{ data.item.line_id }}
                </div>
                <div v-if="data.item.link_facebook !== null">
                  <a target="_blank" :href="data.item.link_facebook">Link</a>
                </div>
                <div v-if="data.item.bank_name !== null">
                  {{ data.item.bank_name }}
                </div>
                <div v-if="data.item.bank_account_name !== null">
                  ชื่อ : {{ data.item.bank_account_name }}
                </div>
                <div v-if="data.item.bank_account !== null">
                  เลขบัญชี : {{ data.item.bank_account }}
                </div>
                <div v-if="data.item.channel_name === 'เบอร์โทรศัพท์'">
                  เบอร์โทร : {{ data.item.phone_number }}
                </div>
                <div v-if="data.item.channel_name === 'TikTok'">
                 ไอดี : {{ data.item.tiktok_id }}
                </div>
              </div>
            </div>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="8"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
              @change="getLine()"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="getLine()"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>

      <!-- Show modal -->

      <b-modal v-model="showInputModel" centered title="นำเข้าข้อมูล">
        <b-form-group class="mr-2" label="เลือกประเภทข้อมูล">
          <b-form-select
            disabled
            v-model="reqType"
            class="mb-1 form-control-input"
            :options="channelOptions"
          />
        </b-form-group>

        <b-form-group class="mr-2" label="สถานะข้อมูลที่จะนำเข้า">
          <b-form-select
            placeholder=""
            v-model="reqInputData"
            class="mb-1 form-control-select w-100"
            :options="reqOptionsInputData"
          />
        </b-form-group>
        <template #modal-footer>
          <div class="w-100" aria-hidden="true">
            <b-button
              variant="primary"
              class="btn btn-danger float-right rounded-sm ml-1"
              @click="
                (showInputModel = false),
                  (checkBoxShow = false),
                  resetforminput()
              "
            >
              ยกเลิก
            </b-button>
            <b-button
              variant="primary"
              class="btn btn-success float-right rounded-sm"
              @click="showCheckBox"
            >
              ยืนยัน
            </b-button>
          </div>
        </template>
      </b-modal>

      <!-- Edit modal -->

      <b-modal v-model="showEditModel" size="lg" centered title="แก้ไขข้อมูล">
        <div class="row">
          <div class="col-12 col-lg-6">
            <p class="title text-center mt-1">ข้อมูลผู้แจ้ง</p>
            <div class="row">
              <validation-provider
                #default="{ errors }"
                name="firstName"
                rules="required"
                class="col-md-6 col-12"
              >
                <b-form-group
                  id="input-group-1"
                  label="ชื่อ"
                  label-for="firstName"
                >
                  <b-form-input
                    id="firstNamedsa"
                    type="text"
                    v-model="dataReport.first_name"
                  ></b-form-input>
                  <small class="text-danger">{{
                    errors[0] ? "กรุณากรอกชื่อ" : ""
                  }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="firstName"
                rules="required"
                class="col-md-6 col-12"
              >
                <b-form-group
                  id="input-group-1"
                  label="นามสกุล"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    placeholder="นามสกุล"
                    v-model="dataReport.last_name"
                  ></b-form-input>
                  <small class="text-danger">{{
                    errors[0] ? "กรุณากรอกนามสกุล" : ""
                  }}</small>
                </b-form-group>
              </validation-provider>
            </div>
            <div class="row">
              <validation-provider
                class="col-md-6 col-12"
                #default="{ errors }"
                name="province_id"
                rules="required"
              >
                <b-form-group
                  id="input-group-1"
                  label="จังหวัด"
                  label-for="input-1"
                >
                  <b-form-select
                    v-model="dataReport.province_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="
                      province_Info.map((province) => ({
                        value: province.province_id,
                        text: province.province_name,
                      }))
                    "
                    @input="filterDistricts"
                  /><small class="text-danger">{{
                    errors[0] ? "กรุณาเลือกจังหวัด" : ""
                  }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                class="col-md-6 col-12"
                #default="{ errors }"
                name="district_id"
                rules="required"
              >
                <b-form-group
                  id="input-group-1"
                  label="อำเภอ/เขต"
                  label-for="input-1"
                >
                  <b-form-select
                    v-model="dataReport.district_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="
                      district_Info.map((ele) => ({
                        value: ele.district_id,
                        text: ele.district_name,
                      }))
                    "
                    @input="filterSubDistricts"
                  /><small class="text-danger">{{
                    errors[0] ? "กรุณาเลือกเขต/อำเภอ" : ""
                  }}</small>
                </b-form-group>
              </validation-provider>
            </div>
            <div class="row">
              <validation-provider
                class="col-md-6 col-12"
                #default="{ errors }"
                name="subdistrict_id"
                rules="required"
              >
                <b-form-group
                  id="input-group-1"
                  label="ตำบล/แขวน"
                  label-for="input-1"
                >
                  <b-form-select
                    v-model="dataReport.subdistrict_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="
                      subdistrict_Info.map((ele) => ({
                        value: ele.tumbon_id,
                        text: ele.sub_district_name,
                      }))
                    "
                    @input="getZipcode"
                  /><small class="text-danger">{{
                    errors[0] ? "กรุณาเลือกแขวง/ตำบล" : ""
                  }}</small>
                </b-form-group>
              </validation-provider>
              <b-form-group
                class="col-md-6 col-12"
                id="input-group-1"
                label="รหัสไปรษณีย์"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  type="text"
                  placeholder="รหัสไปรษณีย์"
                  required
                  v-model="dataReport.zipcode"
                  disabled
                />
              </b-form-group>
            </div>
          </div>

          <div class="col-12 col-lg-6">
            <p class="title text-center mt-1">ข้อมูลมิจฉาชีพ</p>

            <div class="row">
              <b-form-group
                class="col-12"
                id="input-group-1"
                label="ประเภทข้อมูล"
                label-for="input-1"
              >
                <b-form-input v-model="dataReport.channel_name" disabled />
              </b-form-group>
              <div v-if="dataReport.channel_type === 1" class="col-12">
                <b-form-group
                  id="input-group-1"
                  label="หมายเลขโทรศัพท์"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="number"
                    placeholder="หมายเลขโทรศัพท์"
                    required
                    v-model="dataReport.phone_number"
                  ></b-form-input>
                </b-form-group>
                 <validation-provider
                  class="col-12"
                  #default="{ errors }"
                  name="detail"
                  rules="required"
                >
                  <b-form-group
                    id="input-group-1"
                    label="รายละเอียด"
                    label-for="input-1"
                  >
                    <textarea
                      id="description"
                      class="form-control"
                      v-model="dataReport.detail"
                    /><small class="text-danger">{{
                      errors[0] ? "กรุณากรอกรายละเอียด" : ""
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </div>
              <div v-if="dataReport.channel_type === 2" class="col-12">
                <validation-provider
                  #default="{ errors }"
                  name="lineId"
                  rules="required"
                >
                  <b-form-group
                    id="input-group-1"
                    label="ไอดีไลน์"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      type="line"
                      placeholder="ไอดีไลน์"
                      required
                      v-model="dataReport.line_id"
                    /><small class="text-danger">{{
                      errors[0] ? "กรุณากรอกไอดีไลน์" : ""
                    }}</small>
                  </b-form-group>
                </validation-provider>
                 <validation-provider
                  class="col-12"
                  #default="{ errors }"
                  name="detail"
                  rules="required"
                >
                  <b-form-group
                    id="input-group-1"
                    label="รายละเอียด"
                    label-for="input-1"
                  >
                    <textarea
                      id="description"
                      class="form-control"
                      v-model="dataReport.detail"
                    /><small class="text-danger">{{
                      errors[0] ? "กรุณากรอกรายละเอียด" : ""
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </div>
              <div v-if="dataReport.channel_type === 3" class="col-12">
                <validation-provider
                  #default="{ errors }"
                  name="link_facebook"
                  rules="required"
                >
                  <b-form-group
                    id="input-group-1"
                    label="Facebook"
                    label-for="input-1"
                  >
                    <b-form-input
                     v-model="dataReport.link_facebook"
                      id="input-1"
                      type="email"
                      placeholder="Facebook"
                      required
                    ></b-form-input
                    ><small class="text-danger">{{
                      errors[0] ? "กรุณากรอกข้อมูล" : ""
                    }}</small>
                  </b-form-group>
                </validation-provider>
                 <validation-provider
                  class="col-12"
                  #default="{ errors }"
                  name="detail"
                  rules="required"
                >
                  <b-form-group
                    id="input-group-1"
                    label="รายละเอียด"
                    label-for="input-1"
                  >
                    <textarea
                      id="description"
                      class="form-control"
                      v-model="dataReport.detail"
                    /><small class="text-danger">{{
                      errors[0] ? "กรุณากรอกรายละเอียด" : ""
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </div>
              <div v-if="dataReport.channel_type === 4" class="col-12">
                <validation-provider
                  #default="{ errors }"
                  name="bank_code"
                  rules="required"
                >
                  <b-form-group
                    id="input-group-1"
                    label="ชื่อธนาคาร"
                    label-for="input-1"
                  >
                    <b-form-select
                      v-model="dataReport.bank_code"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="
                        bankname.map((ele) => ({
                          value: ele.bankcode,
                          text: ele.name,
                        }))
                      "
                    /><small class="text-danger">{{
                      errors[0] ? "กรุณาเลือกธนาคาร" : ""
                    }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  #default="{ errors }"
                  name="accountNumber"
                  rules="required"
                >
                  <b-form-group
                    id="input-group-1"
                    label="หมายเลขบัญชี"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      type="text"
                      placeholder="หมายเลขบัญชี"
                      v-model="dataReport.bank_account"
                      required
                    ></b-form-input
                    ><small class="text-danger">{{
                      errors[0] ? "กรุณากรอกหมายเลขบัญชี" : ""
                    }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  #default="{ errors }"
                  name="accountNumber"
                  rules="required"
                >
                  <b-form-group
                    id="input-group-1"
                    label="ชื่อบัญชี"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      type="text"
                      placeholder="ชื่อบัญชี"
                      required
                      v-model="dataReport.bank_account_name"
                    ></b-form-input
                    ><small class="text-danger">{{
                      errors[0] ? "กรุณากรอกชื่อบัญชี" : ""
                    }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  class="col-12"
                  #default="{ errors }"
                  name="detail"
                  rules="required"
                >
                  <b-form-group
                    id="input-group-1"
                    label="รายละเอียด"
                    label-for="input-1"
                  >
                    <textarea
                      id="description"
                      class="form-control"
                      v-model="dataReport.detail"
                    /><small class="text-danger">{{
                      errors[0] ? "กรุณากรอกรายละเอียด" : ""
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </div>

              <div v-if="dataReport.channel_type === 5" class="col-12">
                <validation-provider
                  #default="{ errors }"
                  name="tiktok_id"
                  rules="required"
                >
                  <b-form-group
                    id="input-group-1"
                    label="TikTok"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      type="text"
                      placeholder="TikTok"
                      required
                      v-model="dataReport.tiktok_id"
                    ></b-form-input
                    ><small class="text-danger">{{
                      errors[0] ? "กรุณากรอกข้อมูล" : ""
                    }}</small>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  class="col-12"
                  #default="{ errors }"
                  name="detail"
                  rules="required"
                >
                  <b-form-group
                    id="input-group-1"
                    label="รายละเอียด"
                    label-for="input-1"
                  >
                    <textarea
                      id="description"
                      class="form-control"
                      v-model="dataReport.detail"
                    /><small class="text-danger">{{
                      errors[0] ? "กรุณากรอกรายละเอียด" : ""
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              class="btn btn-danger float-right rounded-sm ml-1"
              @click="showEditModel = false"
            >
              ยกเลิก
            </b-button>
            <b-button
              variant="primary"
              class="btn btn-success float-right rounded-sm"
              @click="confirmData(dataReport)"
            >
              ยืนยัน
            </b-button>
          </div>
        </template>
      </b-modal>

      <!-- Confirm modal -->

      <b-modal
        v-model="showconfirmEditModel"
        centered
        title="ยืนยันการแก้ไขข้อมูล"
      >
        <p>คุณยืนยันที่จะแก้ไขข้อมูลหรือไม่?</p>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              class="btn btn-danger float-right rounded-sm ml-1"
              @click="showconfirmEditModel = false"
            >
              ยกเลิก
            </b-button>
            <b-button
              variant="primary"
              class="btn btn-success float-right rounded-sm"
              @click="submitData()"
            >
              ยืนยัน
            </b-button>
          </div>
        </template>
      </b-modal>

      <!-- Confirm import modal -->

      <b-modal
        v-model="showconfirmimportModel"
        centered
        title="ยืนยันการนำเข้าข้อมูล"
      >
        <p>
          คุณต้องการนำเข้าข้อมูลประเภท
          <strong>
            {{
              reqType === 1
                ? "เบอร์โทรศัพท์"
                : reqType === 2
                ? "ไอดีไลน์"
                : reqType === 3
                ? "Facebook"
                : reqType === 4
                ? "บัญชีธนาคาร"
                : reqType === 5
                ? "TikTok"
                : ""
            }}</strong
          >
          ไปยังสถานะ
          <strong>
            {{
              reqInputData === 1
                ? "มีประวัติการแจ้งเบาะแส"
                : reqInputData === 2
                ? "มีประวัติการแจ้งความดำเนินคดี"
                : reqInputData === 3
                ? "ได้รับการยืนยันตัวตนแล้ว"
                : ""
            }} </strong
          >หรือไม่?
        </p>
        <template #modal-footer>
          <span v-if="selectedCheckboxes == 0" class="text-danger"
            >กรุณาเลือกข้อมูลที่จะขำเข้า</span
          >
          <div class="w-100">
            <b-button
              variant="primary"
              class="btn btn-danger float-right rounded-sm ml-1"
              @click="showconfirmimportModel = false"
            >
              ยกเลิก
            </b-button>
            <b-button
              variant="primary"
              class="btn btn-success float-right rounded-sm"
              @click="importData()"
            >
              ยืนยัน
            </b-button>
          </div>
        </template>
      </b-modal>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BInputGroupPrepend,
  BModal,
  BCard,
  BTable,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BButton,
  BCardBody,
  VBToggle,
  BOverlay,
  BIconCloudDownload,
  VBTooltip,
  BFormDatepicker,
  // BFormCheckbox,
  BFormTextarea,
  BForm,
  BFormFile,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
// import { parse } from "json2csv";
import moment from "moment-timezone";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BCardBody,
    BOverlay,
    BForm,
    BIconCloudDownload,
    BFormFile,
    BButton,
    BFormDatepicker,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      required,
      search_type: null,
      search_val: null,
      show: true,
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: null,
      sortDesc: false,
      sortDirection: null,
      filter: null,
      filterOn: [],
      dataReport: [],
      showinput: false,
      showInputModel: false,
      showEditModel: false,
      showconfirmEditModel: false,
      showconfirmimportModel: false,
      reqInputData: 1,
      reqOptionsInputData: [
        { value: 1, text: "มีประวัติการแจ้งเบาะแส" },
        { value: 2, text: "มีประวัติการแจ้งความดำเนินคดี" },
        { value: 3, text: "ได้รับการยืนยันตัวตนแล้ว" },
      ],
      reqType: null,
      channelOptions: [
        { value: null, text: "ทั้งหมด" },
        { value: 1, text: "เบอร์โทรศัพท์" },
        { value: 4, text: "บัญชีธนาคาร" },
        { value: 2, text: "ไอดีไลน์" },
        { value: 3, text: "Facebook" },
        { value: 5, text: "TikTok" },
      ],
      datasidebar: [],
      fields: [
        { key: "index", label: "ลำดับ" },
        { key: "line_displayName", label: "ชื่อไลน์" },
        { key: "full_name", label: "ชื่อ-นามสกุล ผู้แจ้ง" },
        { key: "detailsarea", label: "พื้นที่ (ตำบล/อำเภอ/จังหวัด)" },
        { key: "channel_type", label: "ประเภทช่องทาง" },
        { key: "", label: "ข้อมูล" },
        { key: "detail", label: "รายละเอียด" },
        {
          key: "created_at",
          label: "บันทึกเมื่อ",
          formatter: (value) =>
            value ? moment(value).tz("Asia/Bangkok").format("DD/MM/YYYY") : "",
        },
        { key: "editor", label: "จัดการ" },
      ],
      fields_date: [
        { key: "date_start", label: "วันที่เริ่มต้น" },
        { key: "date_end", label: "วันที่สิ้นสุด" },
      ],
      fields_area: [
        { key: "subdistrict_name", label: "ตำบล" },
        { key: "district_name", label: "อำเภอ" },
        { key: "province_name", label: "จังหวัด" },
      ],
      show_areas: [],
      items_detail: [],
      items_channel: [],
      items_area: [],
      items: [],
      checkBoxShow: false,
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem("userData")),
      dateStart: moment().tz("Asia/Bangkok").format("YYYY-MM-DD"),
      dateEnd: moment().tz("Asia/Bangkok").format("YYYY-MM-DD"),
      selectedFile: null,
      province_Info: [],
      district_Info: [],
      districtALL: [],
      subdistrict_Info: [],
      subdistrictALL: [],
      bankname: [],
      selectedCheckboxes: [],

      first_name: null,
      last_name: null,
      address: null,
      province_id: null,
      district_id: null,
      subdistrict_id: null,
      zipcode: null,

      channel_type: 1,
      phone_number: null,
      line_id: null,
      link_facebook: null,
      tiktok_id: null,
      bank_code: null,
      bank_account: null,
      bank_account_name: null,
      detail: null,

      channel_list: [
        { text: "เบอร์โทรศัพท์", value: 1 },
        { text: "ไอดีไลน์", value: 2 },
        { text: "Facebook", value: 3 },
        { text: "บัญชีธนาคาร", value: 4 },
         { text: "TikTok", value: 5 },
      ],

      locale: "th",
      labels: {
        th: {
          labelPrevDecade: "ทศวรรษก่อนหน้า",
          labelPrevYear: "ปีก่อนหน้า",
          labelPrevMonth: "เดือนก่อนหน้า",
          labelCurrentMonth: "เดือนปัจจุบัน",
          labelNextMonth: "เดือนถัดไป",
          labelNextYear: "ปีถัดไป",
          labelNextDecade: "ทศวรรษถัดไป",
          labelToday: "วันนี้",
          labelSelected: "วันที่ที่เลือก",
          labelNoDateSelected: "ยังไม่ได้เลือกวันที่",
          labelCalendar: "ปฏิทิน",
          labelNav: "การนำทางปฏิทิน",
          labelHelp: "ใช้ปุ่มลูกศรเพื่อเลือกวันที่",
        },
      },
      locales: [{ value: "th", text: "Thai (th)" }],
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    //   this.totalRows = this.items.length
    this.getProvinces();
    this.getDistrict();
    this.getSubdistrict();
    this.getBank();
    this.getLine();
  },

  methods: {
    showarea(item) {
      this.items_area = [
        {
          subdistrict_name: item.item.subdistrict_name,
          district_name: item.item.district_name,
          province_name: item.item.province_name,
        },
      ];
      this.$refs["area"].show();
      console.log(item);
    },
    show_detail(item) {
      this.items_detail = item;
      this.$refs["detail"].show();
      console.log(item);
    },

    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },

    async getLine() {
      this.show = true;
      const params = {
        page: this.currentPage,
        perpage: this.perPage,
        sdate: this.dateStart,
        edate: this.dateEnd,
        reqtype: this.reqType,
      };
      await this.$http
        .get("/infoLine/showform", { params })
        .then((response) => {
          this.show = false;
          if (this.reqType === 1) {
            this.showinput = true;
          }
          if (this.reqType === 2) {
            this.showinput = false;
          }
          if (this.reqType === 3) {
            this.showinput = false;
          }
          if (this.reqType === 4) {
            this.showinput = true;
          }
          this.items = response.data.lineFormData;
          this.totalRows = response.data.total;
          this.checkBoxShow = false;
          this.resetforminput();
        })
        .catch((error) => console.log(error));
    },

    onFiltered(filteredItems) {
      this.items = filteredItems.data;
      this.totalRows = filteredItems.total;
    },

    getDataReport(data) {
      this.dataReport = data.item;
      this.showEditModel = true;
      this.getZipcode();
      this.filterDistricts();
      this.filterSubDistricts();
    },

    confirmData(data) {
      this.confirmDataReport = data;
      this.showconfirmEditModel = true;
    },
    showCheckBox() {
      this.checkBoxShow = true;
      this.showInputModel = false;
    },

    async exportData() {
      this.show = true;
      const params = {
        sdate: this.dateStart,
        edate: this.dateEnd,
      };
      try {
        const response = await this.$http.get("/infoLine/exportexcel", {
          params,
        });
        this.show = false;
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const csvData = new Blob([bom, response.data], {
          type: "text/csv;charset=utf-8;",
        });
        const now = new Date();
        const dateString = now.toISOString().slice(0, 10); // YYYY-MM-DD
        const timeString = now.toTimeString().split(" ")[0].replace(/:/g, "-"); // HH-MM-SS
        const fileName = `FormLine_${dateString}_${timeString}.csv`;

        const link = document.createElement("a");
        const url = window.URL.createObjectURL(csvData);
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.log(error);
        this.show = false;
      }
    },
    async getProvinces() {
      try {
        const response = await this.$http.get("/get/province");
        this.province_Info = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getDistrict() {
      try {
        const response = await this.$http.get("/get/district");
        this.district_Info = response.data;
        this.districtALL = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getSubdistrict() {
      try {
        const response = await this.$http.get("/get/subdistrict");
        this.subdistrict_Info = response.data;
        this.subdistrictALL = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getZipcode() {
      if (this.dataReport.subdistrict_id) {
        try {
          const params = {
            tumbon_id: this.dataReport.subdistrict_id,
          };
          const response = await this.$http.get("/get/zipcode", { params });
          this.dataReport.zipcode = response.data[0].zipcode;
        } catch (error) {
          console.log(error);
        }
      }
    },
    async getBank() {
      try {
        const response = await this.$http.get("/get/bank");
        this.bankname = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    filterDistricts() {
      if (this.dataReport.province_id) {
        this.district_Info = this.districtALL.filter(
          (district) => district.province_id === this.dataReport.province_id
        );
        this.subdistrict_Info = this.subdistrictALL.filter(
          (subdistrict) =>
            subdistrict.changwat_id === this.dataReport.province_id
        );
      }
    },

    filterSubDistricts() {
      if (this.dataReport.district_id) {
        this.subdistrict_Info = this.subdistrictALL.filter(
          (subdistrict) => subdistrict.ampur_id === this.dataReport.district_id
        );
        this.getZipcode();
      }
    },
    async submitData() {
      this.$refs.formRules.validate().then(async (success) => {
        if (success) {
          const channel = this.channel_list.find(
            (ele) => ele.value === this.dataReport.channel_type
          );
          const province = this.province_Info.find(
            (ele) => ele.province_id === this.dataReport.province_id
          );
          const district = this.district_Info.find(
            (ele) => ele.district_id === this.dataReport.district_id
          );
          const subdistrict = this.subdistrict_Info.find(
            (ele) => ele.tumbon_id === this.dataReport.subdistrict_id
          );
          const payload = {
            id: this.dataReport._id,
            first_name: this.dataReport.first_name,
            last_name: this.dataReport.last_name,
            address: null,
            province_id: this.dataReport.province_id,
            province_name: province.province_name,
            district_id: this.dataReport.district_id,
            district_name: district.district_name,
            subdistrict_id: this.dataReport.subdistrict_id,
            subdistrict_name: subdistrict.sub_district_name,
            zipcode: this.dataReport.zipcode,
            channel_type: this.dataReport.channel_type,
            channel_name: channel?.text,
            phone_number: this.dataReport.phone_number,
            line_id: this.dataReport.line_id,
            link_facebook: this.dataReport.link_facebook,
            bank_code: this.dataReport.bank_code,
            bank_name: this.dataReport.bank_name,
            bank_account: this.dataReport.bank_account,
            bank_account_name: this.dataReport.bank_account_name,
            detail: this.dataReport.detail,
            tiktok_id: this.dataReport.tiktok_id,
          };
          console.log(payload);
          try {
            const { data: res } = await this.$http.post(
              "/infoLine/updateInfo",
              payload
            );
            if (res.status) {
              this.$swal({
                icon: "success",
                title: '<h3 style="color: #141414">บันทึกข้อมูลเรียบร้อย</h3>',
                text: "",
                confirmButtonText: "ปิด",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.getLine();
              this.showEditModel = false;
              this.showconfirmEditModel = false;
            } else {
              this.$swal({
                icon: "error",
                title:
                  '<h3 style="color: #141414">เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>',
                text: "",
                confirmButtonText: "ปิด",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              this.showEditModel = false;
              this.showconfirmEditModel = false;
            }
          } catch (error) {
            this.$swal({
              icon: "error",
              title:
                '<h3 style="color: #141414">เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>',
              text: "",
              confirmButtonText: "ปิด",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            this.showEditModel = false;
            this.showconfirmEditModel = false;
          }
        }
      });
    },

    resetforminput() {
      this.selectedCheckboxes = [];
      this.showEditModel = false;
      this.showconfirmEditModel = false;
    },

    async importData() {
      const params = {
        datalist: this.selectedCheckboxes,
        level: this.reqInputData,
        channel_type: this.reqType,
      };
      console.log(params);
      try {
        const { data: res } = await this.$http.post(
          "/infoLine/importLineform",
          params
        );

        this.showInputModel = false;
        this.showconfirmimportModel = false;
        if (res.status) {
          this.$swal({
            icon: "success",
            title: '<h3 style="color: #141414">บันทึกข้อมูลเรียบร้อย</h3>',
            text: "",
            confirmButtonText: "ปิด",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.showEditModel = false;
          this.showconfirmEditModel = false;
        } else {
          this.$swal({
            icon: "error",
            title:
              '<h3 style="color: #141414">เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>',
            text: "",
            confirmButtonText: "ปิด",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          this.showEditModel = false;
          this.showconfirmEditModel = false;
        }
      } catch (error) {
        this.$swal({
          icon: "error",
          title:
            '<h3 style="color: #141414">เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>',
          text: "",
          confirmButtonText: "ปิด",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        this.showEditModel = false;
        this.showconfirmEditModel = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.icon-color-waring:hover {
  color: #ff9900;
}

.pen:hover {
  box-shadow: 2px 2px 8px 4px rgba(0, 0, 0, 0.1);
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.date-input {
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  width: auto;
  text-align: center;
  background-color: rgb(255, 255, 255);
  color: #000000;
  font-size: 14px;
  transition: border-color 0.3s ease;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}

.date-input:hover {
  border-color: #003b7a;
}

.date-input:focus {
  border-color: #242424;
  outline: none;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}

.date-input-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.date-label {
  font-weight: bold;
  font-size: 14px;
  color: #343a40;
}

.custom-title {
  color: #ffffff;
}
.table-center {
  text-align: center;
}

.form-control-select {
  padding: 0.438rem 1rem !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #d8d6de !important;
  border-radius: 0.357rem !important;
}

@media (max-width: 800px) {
  .custom-card {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
